import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Lucas Fonseca', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Lucas Fonseca',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.linkedin.com/in/lucasfonmiranda/', // if no resume, the button will not show up
};

// REVIEW COMMENTS
export const reviews = [
  {
    id: nanoid(),
    person: 'Daniel',
    comment: 'Lucas gave me great feedback, was extremely flexible, and guided our mock interview to the most important topics for the company I am interviewing with. He also shared insights on the type work he has done at that company to help me understand what I could end up working on there.',
    date: 'Nov 22, 2021',
  },
  {
    id: nanoid(),
    person: 'Christopher',
    comment: 'I needed help with my facebook data engineer full loop (virtual onsite) interview and Lucas has been amazingly helpful. He\'s gone out of his way to make sure I got a ton of feedback, and given me tips that really got me out of some ruts I was stuck in for certain parts of my technical ETL portions. His setup of a interview with certain pauses to correct me in the moments have been instrumental in increasing my confidence and methodology to approaching this interview. I feel significantly more confident about my chances having worked with Lucas.',
    date: 'Nov 15, 2021',
  },
  {
    id: nanoid(),
    person: 'Michael',
    comment: 'Lucas was extremely helpful guiding my thought process through this interview. I feel much more confident going into my interviews due to his help and how this mock interview was setup with the use of a virtual whiteboard as well as the interview challenge. Lucas also allowed the conversation to last beyond the one hour allotted in order to give the feedback that he wrote about our session.',
    date: 'Nov 06, 2021',
  },
];

// ARTICLES DATA
export const articlesData = [
  {
  
    id: nanoid(),
    img: 'airflow-article.jpg',
    title: 'Running Airflow on Heroku (Part II)',
    info: 'In the previous article, we configured the environment to run Airflowlocally in this article, we are going to configure your Herokuand make the first deployment of our application.',
    date: '14 December 2021',
    url: 'https://medium.com/p/c9797a36f11f',
  },
  {
  
    id: nanoid(),
    img: 'streamlit.jpeg',
    title: 'Create a simple data app with Streamlit',
    info: 'Have you ever thought about creating a data app in less than 15 minutes? With the Steamlit lib, it’s possible!',
    date: '14 December 2021',
    url: 'https://blog.devgenius.io/create-a-simple-data-app-with-streamlit-3aebea86e1f9',
  },
  {
    id: nanoid(),
    img: 'airflow-article.jpg',
    title: 'Start using Airflow on Heroku (First Step)',
    info: 'Whenever we start to develop something new in the data engineering area, we want to place our project in an environment that reproduces the production environment, thus being able to understand all the thresholds of our architecture...',
    date: '06 December 2021',
    url: 'https://blog.devgenius.io/start-using-airflow-on-heroku-first-step-cc4d8fe3c921',
  },
  // {
  //   id: nanoid(),
  //   img: 'cto-article.jpg',
  //   title: 'The first week in CTO position',
  //   info: 'I believe that the dream of 70% of software engineers, developers and architects is to take a leadership one. Maybe even each architects is to take a leadership role. Maybe even reach the position of CTO or even build your Startup from scratch to take on that role from there...',
  //   date: '25 March 2021',
  //   url: 'https://lucas-fonmiranda.medium.com/the-first-week-in-cto-position-98519ee63b58'
  // },
];


// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'lucas@lucasfonmiranda.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'calendar',
    //   url: 'https://calendly.com/lucas-fonseca',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/lucasfonmiranda/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
